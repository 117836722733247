import servers from "./servers";

export default {
  [servers.user]: "https://testuser-api.ecoach.evideo.tech",
  [servers.device]: "https://testapi.ecoach.evideo.tech",
  [servers.platform]: "https://testplatform-api.ecoach.evideo.tech",
  [servers.pptist]: "https://testmusic-score.ppt.evideo.tech", // "http://localhost:8090/", //
  [servers.ecoach]: "https://testmusic-score.ecoach.evideo.tech",
  [servers.resource]: "https://testresource-center.ecoach.evideo.tech",
  [servers.ec]: "https://testec.ecoach.evideo.tech/stat",
  [servers.qrcode]:
    "https://testgateway.ecoach.evideo.tech/api/common-service/",
  [servers.kefuQrcode]:
    "https://testgateway.ecoach.evideo.tech/api/common-service/",
  [servers.authorization]: "https://testapi.ecoach.evideo.tech",
  [servers.musicGame]: "https://testinteractive-teach.ecoach.evideo.tech",
  [servers.common]:
    "https://testgateway.ecoach.evideo.tech/api/common-service/",
  [servers.tools]: "https://testh5-tools.ecoach.evideo.tech",
  [servers.exam]: "https://testexam-center-service.ecoach.evideo.tech",
  [servers.localResource]: "http://127.0.0.1",
  [servers.dingtalkCallback]:
    "https://testmulti-platform.teaching.evideo.tech/login",
  [servers.school]: "https://testschool.ecoach.evideo.tech",
  [servers.deviceControlService]: "http://127.0.0.1",
  [servers.cloud]: "https://testcloud-pre-render-service.ecoach.evideo.tech",
  [servers.app]: "https://testmulti-platform.teaching.evideo.tech",
  [servers.api]: "https://testapi.ecoach.evideo.tech/",
};
